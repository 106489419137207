import React, { Component } from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Scrollspy from "react-scrollspy"
import { Menu, X } from "react-feather"
import styled from "styled-components"
import { Container } from "../../global"
import {
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
  ActionsContainer,
} from "./style"
import dililogo from "../../../images/icons/dili-logo.png"
import { Link } from "gatsby"

const NAV_ITEMS = ["How it works", "Where to shop", "Company", "Merchants"]

export default class Navigation extends Component {
  state = {
    mobileMenuOpen: false,
    hasScrolled: false,
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = (event) => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 32) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }

  toggleMobileMenu = () => {
    this.setState((prevState) => ({
      mobileMenuOpen: !prevState.mobileMenuOpen,
    }))
  }

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false })
    }
  }

  getNavAnchorLink = (item) => (
    <AnchorLink href={`#${item.toLowerCase()}`} onClick={this.closeMobileMenu}>
      {item}
    </AnchorLink>
  )

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <Scrollspy
        items={NAV_ITEMS.map((item) => item.toLowerCase())}
        currentClassName="active"
        mobile={mobile}
        offset={-64}
      >
        <NavLink to="/" activeStyle={{ color: "#142630" }}>
          Home
        </NavLink>
        <NavLink to="/product-details/" activeStyle={{ color: "#142630" }}>
          Product Details
        </NavLink>
        <NavLink to="/wheretoshop/" activeStyle={{ color: "#142630" }}>
          Shop Now
        </NavLink>
        <NavLink to="/merchants/" activeStyle={{ color: "#142630" }}>
          For Merchants
        </NavLink>
        <NavLink to="/about/" activeStyle={{ color: "#142630" }}>
          About Us
        </NavLink>
        <NavLink to="/blogs/blogs/" activeStyle={{ color: "#142630" }}>
          Blog
        </NavLink>
      </Scrollspy>
    </NavListWrapper>
  )

  render() {
    const { mobileMenuOpen } = this.state

    return (
      <Nav {...this.props} scrolled={this.state.hasScrolled}>
        <StyledContainer>
          <Brand>
            <Scrollspy offset={-64} item={["top"]} currentClassName="active">
              <Link to="/" onClick={this.closeMobileMenu}>
                <img src={dililogo} alt="Logo" height="72px" />
              </Link>
            </Scrollspy>
          </Brand>
          <Mobile>
            <button
              onClick={this.toggleMobileMenu}
              style={{ color: "black", background: "none" }}
            >
              {this.state.mobileMenuOpen ? (
                <X size={24} alt="close menu" />
              ) : (
                <Menu size={24} alt="open menu" />
              )}
            </button>
          </Mobile>

          <Mobile hide>{this.getNavList({})}</Mobile>
          <ActionsContainer>
            {/* <a href="https://consumer.dilinow.com/"><button>Login</button></a> */}
            <a href="https://customer.dilinow.com/"><button>Login</button></a>
            {/* <RegisterLink to="/404/">
              <button>Login</button>
            </RegisterLink> */}
            <RegisterLink to="/404/">Register</RegisterLink>
          </ActionsContainer>
        </StyledContainer>
        <Mobile>
          {mobileMenuOpen && (
            <MobileMenu>
              <Container>{this.getNavList({ mobile: true })}</Container>
            </MobileMenu>
          )}
        </Mobile>
      </Nav>
    )
  }
}

export const NavLink = styled(Link)`
  margin-right: 14px;
  margin-left: 14px;
  margin-top: 0px;
  padding: 0;
  font-size: 18px;
  color: grey;
  text-decoration: none;
`

export const RegisterLink = styled(NavLink)`
  color: #e87d00;
  margin-left: 20px;
`
