import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Layout from "../../components/common/layout/layout"
import SEO from "../../components/common/layout/seo"
import Navigation from "../../components/common/navigation/navigation"
import Footer from "../../components/sections/footer"
import Img from 'gatsby-image'

const Documents = () => (
  <Layout>
    <SEO title="Document Requirements | Dili - Zero Cost EMI Option for Professional Skilling" />
    <Navigation></Navigation>
    <CategoryContainer>
        <CategoryHeader>Document Requirements</CategoryHeader>
        <CategoryText>For our merchant application, we require the following documents:
        <br />
        <br />
        <ol>
          <li>Pan Card / Company Pan Card / Partnership Firm Pan Card<br/><br/></li>
          <li>GST Certificate<br/><br/></li>
          <li>
            <ul>
              <li>Shop and Establishment License</li>
              <li>Copy Of Partnership Deed or Certificate of Registration</li>
            </ul><br/>
          </li>
          <li>Cancelled Bank Cheque<br/><br/></li>
          <li>Address Proof (Any 1) <br/>
            <ul>
              <li>Telephone or Electricity Bill</li>
              <li>Bank Passbook or Account Statement</li>
              <li>Registered Lease</li>
              <li>Sale Agreement of office premises</li>
              <li>Proof of Address issued by Scheduled Commercial Banks</li>
              <li>Registration Certificate issued under Shops and Establishment Act</li>
            </ul><br/>
          </li>
          <li>ID Proof of authorized signatory submitting request for CCR (ANY 1) / ID Proof of Proprietor:<br />
            <ul>
              <li>PAN</li>
              <li>Driving License</li>
              <li>Passport</li>
            </ul><br/>
          </li>
          <li>Photo / List of Authorized Signatories with specimen signatures<br/><br/></li>
        </ol>
        </CategoryText>
    <Spacing />
    </CategoryContainer>
    <Footer />
  </Layout>
)

export default Documents


export const Spacing = styled.div`
    margin-bottom: 120px;
`

export const CategoryContainer = styled.div`
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 160px;
`

export const CategoryHeader = styled.div`
    color: #142630;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: -0.02em;
    margin-right: 40px;
`

export const CategoryText = styled.div`
    color: #465F6D;
    font-size: 20px;
    letter-spacing: -0.02em;
    line-height: 1.5;
    margin-top: 20px;
    width: 70%;
`

export const Icon = styled.img`
    width: 80px;
    height: 80px;
    margin-bottom: 32px;
`

export const Stores = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

export const Store = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 32px;
    margin-bottom: 80px;
`

export const StoreImg = styled(Img)`
    margin-bottom: 16px;
    border-radius: 20px;
`

export const StoreHeader = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
    font-weight: bold;
    width: 320px;
`

export const StoreText = styled.div`
    font-size: 18px;
    color: grey;
    margin-bottom: 16px;
    width: 320px;
    line-height: 1.3;
`

export const StoreButton = styled.button`
    background: black;
    color: white;
    font-weight: bold;
    font-size: 14px;
    width: 96px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 40px;
`

export const StoreC = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`


export const CLink = styled(Link)`
    color: #0084DD;
`
